var ContractConfirmation = {
  init: function() {
    this.assign_event_handlers();
  },
  assign_event_handlers: function() {
    // set with initial values
    $('.account-training-frequency').html($('#student_training_frequency').val());
    $('.account-date-joined').html(ContractConfirmation.convert_to_short_date($('#student_date_joined').val()));
    
    $(document).on('click', '#next-option', function(e) {
      e.preventDefault();
      $('#confirm-contract').modal('show');
      $('#contract-student-list').html('');
      ContractConfirmation.student_list();
    });

    $(document).on('click', '#confirm-contract-continue', function(e) {
      $('#confirm-contract').modal('hide');
      $('.admin-student-registration-form').trigger('submit');
    });

    $(document).on('blur', '#student_main_contact_first_name', function() {
      ContractConfirmation.set_account_name();
    });

    $(document).on('blur', '#student_main_contact_last_name', function() {
      ContractConfirmation.set_account_name();
    });

    $(document).on('blur', '#student_main_contact_email', function() {
      $('.account-email').html($('#student_main_contact_email').val());
    });

    $(document).on('blur', '#student_main_contact_mobile_phone', function() {
      $('.account-phone').html($('#student_main_contact_mobile_phone').val());
    });

    $(document).on('change', '#student_location_id', function() {
      $('.account-location').html($('#student_location_id option:selected').text());
    });

    $(document).on('change', '#student_date_joined', function() {
      $('.account-date-joined').html(ContractConfirmation.convert_to_short_date($('#student_date_joined').val()));
    });

    $(document).on('change', '#student_training_frequency', function() {
      $('.account-training-frequency').html($('#student_training_frequency').val());
    });

    $(document).on('change', '#payment-method', function() {
      $('.account-payment-method').html($('#payment-method option:selected').text());
    });

    $(document).on('blur', '#student_joining_fee', function() {
      $('.account-joining-fee').html(ContractConfirmation.set_joining_fee_info());
    });

    $(document).on('blur', '#student_joining_fee_payment_date', function() {
      $('.account-joining-fee').html(ContractConfirmation.set_joining_fee_info());
    });

    // first installment info
    $(document).on('blur', '#student_weekly_training_fee_amount', function() {
      $('.account-first-installment').html(ContractConfirmation.first_installment_info());
    });

    $(document).on('blur', '#student_account_setup_fee', function() {
      $('.account-first-installment').html(ContractConfirmation.first_installment_info());
    });

    $(document).on('blur', '#student_first_instalment_date', function() {
      $('.account-first-installment').html(ContractConfirmation.first_installment_info());
    });
    // first installment info

    // ongoing fees info
    $(document).on('change', '#student_total_instalment_amount', function() {
      $('.account-ongoing-fees').html(ContractConfirmation.set_ongoing_fees_info());
    });

    $(document).on('change', '#student_payment_frequency', function() {
      $('.account-ongoing-fees').html(ContractConfirmation.set_ongoing_fees_info());
    });
    // ongoing fees info
  },
  student_list: function() {
    // loop cloneable-student-details-fields
    $('.student-details-fields').each(function(i, obj) {
      var student_name = $(this).find('.student-first-name').val() + ' ' + $(this).find('.student-last-name').val();
      var student_dob = ContractConfirmation.convert_to_short_date($(this).find('.student-dob').val());
      var student_email = $(this).find('.student-email').val();
      var stuent_phone = $(this).find('.student-phone').val();
      var student_first_class = 'First class ' + ContractConfirmation.convert_to_short_date($(this).find('.student-fcd').val());
      var student_pass_type = $(this).find('.pass-type').val();
      var student_membership = $(this).find('.student-membership-offer').val();
      var student_note = $(this).find('.student-note').val();

      var student_detail = '<div class="student-details col mb-4">';
      student_detail += '<p><strong>Student ' + (i + 1) + '</strong></p>';
      student_detail += '<p class="mb-0">' + student_name + '</p>';
      student_detail += '<p class="mb-0">' + student_dob + '</p>';
      student_detail += '<p class="mb-0">' + student_email + '</p>';
      student_detail += '<p class="mb-0">' + stuent_phone + '</p>';
      student_detail += '<p class="mb-0">' + student_first_class + '</p>';
      student_detail += '<p class="mb-0">' + student_pass_type + '</p>';
      student_detail += '<p class="mb-0">' + student_membership + '</p>';
      if (student_note != '') {
        student_detail += '<p>' + student_note + '</p>';
      }
      student_detail += '</div>';
      
      $('#contract-student-list').append(student_detail);
    })
  },
  set_ongoing_fees_info: function() {
    var installment_amount = $('#student_total_instalment_amount').val();
    var frequency = $('#student_payment_frequency option:selected').text();

    return ContractConfirmation.currency_symbol() + installment_amount + ' ' + frequency;
  },
  first_installment_info: function() {
    var weekly_training_fee = $('#student_weekly_training_fee_amount').val();
    var admin_fee = $('#student_account_setup_fee').val();

    var payment_date = ContractConfirmation.convert_to_short_date($('#student_first_instalment_date').val());
    var total_first_installment_fee = parseFloat(weekly_training_fee) + parseFloat(admin_fee);

    return ContractConfirmation.currency_symbol() + total_first_installment_fee + ' on ' + payment_date;
  },
  set_joining_fee_info: function() {
    var joining_fee = $('#student_joining_fee').val();
    var payment_date = ContractConfirmation.convert_to_short_date($('#student_joining_fee_payment_date').val());

    return ContractConfirmation.currency_symbol() + joining_fee + ' on ' + payment_date;
  },
  currency_symbol: function() {
    return $('#student_country').val() == 'United Kingdom' ? '£' : '$';
  },
  convert_to_short_date: function(date) {
    if (ContractConfirmation.valid_date(date)) {
      var date = new Date(date);
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var year = date.getFullYear();

      return day + '/' + month + '/' + year;
    } else {
      return '';
    }
  },
  valid_date: function(date) {
    var date = new Date(date.replace(/-/g, '/'));
    return date instanceof Date && !isNaN(date);
  },
  set_account_name: function() {
    var first_name = $('#student_main_contact_first_name').val();
    var last_name = $('#student_main_contact_last_name').val();
    var account_name = first_name + ' ' + last_name;

    $('.account-name').html(account_name);
  }
};

document.addEventListener("turbolinks:load", () => {
  if ($('.admin-student-registration-form')[0]) {
    ContractConfirmation.init();
  }
});
